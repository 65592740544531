export const PermissaoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO",
	CRIAR: "CRIAR_USUARIO",
	EXCLUIR: "EXCLUIR_USUARIO",
};

export const PermissaoGrupoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoEmpresaEnum = {
	VISUALIZAR: "VISUALIZAR_EMPRESA",
	CRIAR: "CRIAR_EMPRESA",
	EXCLUIR: "EXCLUIR_EMPRESA",
	DESATIVAR: "DESATIVAR_EMPRESA",
	DEFINIR_RESPONSAVEL_SETOR: "DEFINIR_RESPONSAVEL_SETOR",
	VISUALIZAR_ALERTAS_EMPRESAS: "VISUALIZAR_ALERTAS_EMPRESAS",
};

export const PermissaoRegraEnum = {
	VISUALIZAR: "VISUALIZAR_REGRA",
	CRIAR: "CRIAR_REGRA",
	EXCLUIR: "EXCLUIR_REGRA",
	VERIFICAR: "VERIFICAR_REGRA",
	VISUALIZAR_ALERTAS_REGRAS: "VISUALIZAR_ALERTAS_REGRAS",
};

export const PermissaoAlertaEnum = {
	VISUALIZAR: "VISUALIZAR_ALERTA",
	RESOLVER: "RESOLVER_ALERTA",
	FILTRAR_ALERTA_POR_RESPONSAVEL: "FILTRAR_ALERTA_POR_RESPONSAVEL",
};

export const PermissaoConfiguracaoGeralEnum = {
	CONFIGURAR: "CONFIGURAR_CONFIGURACAO_GERAL",
};

export const PermissaoCelulaEnum = {
	VISUALIZAR: "VISUALIZAR_CELULA",
	CRIAR: "CRIAR_CELULA",
	EXCLUIR: "EXCLUIR_CELULA",
};
