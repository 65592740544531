import {
	HomeOutlined,
	UserOutlined,
	UsergroupAddOutlined,
	CheckOutlined,
	GoldOutlined,
	NotificationOutlined,
	TeamOutlined,
	ThunderboltOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoRegraEnum,
	PermissaoGrupoUsuarioEnum,
	PermissaoUsuarioEnum,
	PermissaoEmpresaEnum,
	PermissaoAlertaEnum,
	PermissaoConfiguracaoGeralEnum,
	PermissaoCelulaEnum,
} from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "lista-usuarios",
		path: `${APP_PREFIX_PATH}/lista-usuarios`,
		title: "lista-usuarios",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-grupos-usuario",
		path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
		title: "lista-grupos-usuario",
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoGrupoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-celulas",
		path: `${APP_PREFIX_PATH}/lista-celulas`,
		title: "lista-celulas",
		icon: TeamOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoCelulaEnum.VISUALIZAR,
	},
	{
		key: "lista-empresas",
		path: `${APP_PREFIX_PATH}/lista-empresas`,
		title: "lista-empresas",
		icon: GoldOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoEmpresaEnum.VISUALIZAR,
	},
	{
		key: "lista-regras",
		path: `${APP_PREFIX_PATH}/lista-regras`,
		title: "lista-regras",
		icon: CheckOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoRegraEnum.VISUALIZAR,
	},
	{
		key: "lista-regras-alertas",
		path: `${APP_PREFIX_PATH}/lista-regras-alertas`,
		title: "lista-regras-alertas",
		icon: NotificationOutlined,
		breadcrumb: false,
		indexNotificacoes: "empresasComErros",
		submenu: [],
		permissao: PermissaoAlertaEnum.VISUALIZAR,
	},
	{
		key: "configuracoes-gerais",
		path: `${APP_PREFIX_PATH}/configuracoes-gerais`,
		title: "configuracoes-gerais",
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoConfiguracaoGeralEnum.CONFIGURAR,
	},
	{
		key: "execucao-rapida",
		path: `${APP_PREFIX_PATH}/execucao-rapida`,
		title: "execucao-rapida",
		icon: ThunderboltOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoRegraEnum.VERIFICAR,
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
